<template>
  <el-main class="rh-medtable">
    <h1>Formations post-graduées</h1>
    <app-table
      tablenom="medfpostgrads"
      :cols="colonnes"
      :items="fpostgrads"
      :loadstatus="fpostgradsLoadStatus"
      :deletestatus="fpostgradDeleteStatus"
      :formroutes="fpgFormRoutes"
      :actions="fpgActions"
      :ismeddetail="true"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      idMed: this.$route.params.idmed, // récupère le id du médecin dans l'url
      fpgFormRoutes: {
        edit: {
          route: "med_fpostgrads_edit",
          params: { idmed: "id_med", idfpg: "id" },
        },
        create: {
          route: "med_fpostgrads_create",
          params: { idmed: this.$route.params.idmed },
        },
      },
    };
  },
  computed: {
    fpostgradsLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.fpg.fpostgradsLoadStatus;
    },
    fpostgradDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.fpg.fpostgradDeleteStatus;
    },
    fpostgrads() {
      // n'affiche que les fpostgrads du médecin sélectionné
      return this.$store.state.fpg.fpostgrads.filter(
        (c) => c.id_med == this.idMed
      );
    },
    colonnes() {
      // n'affiche pas la colonne 'noms des médecins'
      return this.$store.state.fpg.colonnes.filter(
        (col) => col.prop != "medecin"
      );
    },
    fpgActions() {
      return this.$store.state.fpg.actions;
    },
  },
};
</script>
